import { useEffect, useState } from "react";
import "./App.css";
import { Installed } from "./components/Installed/Installed";
import { Main } from "./components/Main/Main";
import { useReactPWAInstall } from "react-pwa-install";
import { Route, Routes } from "react-router-dom";

function App() {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const [animating, setAnimating] = useState(0);
  const [megabyte, setMegabyte] = useState(0);

  const param = new URLSearchParams(document.location.search);

  let external_id = param.get("external_id");
  let creative_id = param.get("creative_id");
  let sub_id_1 = param.get("sub_id_1");
  let sub_id_2 = param.get("sub_id_2");
  let sub_id_3 = param.get("sub_id_3");
  let creo_id = param.get("creo_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let source = param.get("source");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let offer = param.get("offer");
  let id = param.get("id");

  if (id) {
    localStorage.setItem("id", id);
  } else {
    id = localStorage.getItem("id");
  }
  if (cost) {
    localStorage.setItem("cost", cost);
  } else {
    cost = localStorage.getItem("cost");
  }
  if (sub_id_1) {
    localStorage.setItem("sub_id_1", sub_id_1);
  } else {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }
  if (sub_id_2) {
    localStorage.setItem("sub_id_2", sub_id_2);
  } else {
    sub_id_2 = localStorage.getItem("sub_id_2");
  }
  if (sub_id_3) {
    localStorage.setItem("sub_id_3", sub_id_3);
  } else {
    sub_id_3 = localStorage.getItem("sub_id_3");
  }

  if (creo_id) {
    localStorage.setItem("creo_id", creo_id);
  } else {
    creo_id = localStorage.getItem("creo_id");
  }
  if (external_id) {
    localStorage.setItem("external_id", external_id);
  } else {
    external_id = localStorage.getItem("external_id");
  }
  if (site_id) {
    localStorage.setItem("site_id", site_id);
  } else {
    site_id = localStorage.getItem("site_id");
  }
  if (creative_id) {
    localStorage.setItem("creative_id", creative_id);
  } else {
    creative_id = localStorage.getItem("creative_id");
  }
  if (ad_campaign_id) {
    localStorage.setItem("ad_campaign_id", ad_campaign_id);
  } else {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }
  if (source) {
    localStorage.setItem("source", source);
  } else {
    source = localStorage.getItem("source");
  }

  if (offer) {
    localStorage.setItem("offer", offer);
  } else {
    offer = localStorage.getItem("offer");
  }

  // useEffect(() => {
  //   if (localStorage.getItem("isAlreadyDownload")) {
  //     window.location.href =
  //       `https://arumo.top/GLJkpJ4M?a=b` +
  //       (sub_id_1 !== null ? `&sub_id_1=${sub_id_1}` : "") +
  //       (sub_id_2 !== null ? `&sub_id_2=${sub_id_2}` : "") +
  //       (sub_id_3 !== null ? `&sub_id_3=${sub_id_3}` : "");
  //   }
  // }, []);

  useEffect(() => {
    const isClickedInstall = localStorage.getItem("isClickedInstall");
    if (isClickedInstall) {
      localStorage.removeItem("isClickedInstall");
      if (offer === "CmzG6Dkt") {
        window.location.href = `https://arumo.top/CmzG6Dkt?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (offer === "G3qQdFM7") {
        window.location.href = `https://arumo.top/G3qQdFM7?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (offer === "WFLZf7DD") {
        window.location.href = `https://arumo.top/WFLZf7DD?ad_campaign_id=${ad_campaign_id}`;
      } else if (offer === "g3LKs2PF") {
        window.location.href = `https://arumo.top/g3LKs2PF?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (offer === "9NPZBMJX") {
        window.location.href = `https://arumo.top/9NPZBMJX?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1367") {
        window.location.href = `https://arumo.top/s4zmGtMW?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1370") {
        window.location.href = `https://arumo.top/yYQ4dHSw?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1365") {
        window.location.href = `https://arumo.top/b2yrLWXY?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}&sub_id_1=${sub_id_1}`;
      } else if (id === "1371") {
        window.location.href = `https://arumo.top/DVXdfgbT?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}&sub_id_1=${sub_id_1}&sub_id_2=${sub_id_2}`;
      } else if (id === "1452") {
        window.location.href = `https://arumo.top/CG9F7Y46?cost=${cost}&external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1454") {
        window.location.href = `https://arumo.top/4Pk76XTD?cost=${cost}&external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1485") {
        window.location.href = `https://arumo.top/gdxVcd?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1486") {
        window.location.href = `https://arumo.top/1kT1tGf8?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1517") {
        window.location.href = `https://arumo.top/wKFxfy89?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1516") {
        window.location.href = `https://arumo.top/ZzsdX7?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}&id=1516`;
      } else {
        window.location.href = `https://arumo.top/CmzG6Dkt?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      }
    }
  }, []);

  const animationMegabyte = () => {
    setTimeout(() => {
      setMegabyte(0.82);
    }, 1500);

    setTimeout(() => {
      setMegabyte(1.72);
    }, 2700);

    setTimeout(() => {
      setMegabyte(2.47);
    }, 4200);

    setTimeout(() => {
      setMegabyte(2.83);
    }, 5100);

    setTimeout(() => {
      setMegabyte(3.24);
    }, 6000);

    setTimeout(() => {
      setMegabyte(3.53);
    }, 7800);

    setTimeout(() => {
      setMegabyte(3.93);
    }, 9800);

    setTimeout(() => {
      setMegabyte(4.43);
    }, 11700);

    setTimeout(() => {
      setMegabyte(4.7);
    }, 13600);

    setTimeout(() => {
      setMegabyte("Installing...");
    }, 15400);
  };

  const handleClick = () => {
    const userAgent = window.navigator.userAgent;

    if (/iPhone/i.test(userAgent)) {
      if (offer === "CmzG6Dkt") {
        window.location.href = `https://arumo.top/CmzG6Dkt?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (offer === "G3qQdFM7") {
        window.location.href = `https://arumo.top/G3qQdFM7?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (offer === "WFLZf7DD") {
        window.location.href = `https://arumo.top/WFLZf7DD?ad_campaign_id=${ad_campaign_id}`;
      } else if (offer === "g3LKs2PF") {
        window.location.href = `https://arumo.top/g3LKs2PF?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (offer === "9NPZBMJX") {
        window.location.href = `https://arumo.top/9NPZBMJX?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1367") {
        window.location.href = `https://arumo.top/s4zmGtMW?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1370") {
        window.location.href = `https://arumo.top/yYQ4dHSw?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1365") {
        window.location.href = `https://arumo.top/b2yrLWXY?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}&sub_id_1=${sub_id_1}`;
      } else if (id === "1371") {
        window.location.href = `https://arumo.top/DVXdfgbT?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}&sub_id_1=${sub_id_1}&sub_id_2=${sub_id_2}`;
      } else if (id === "1452") {
        window.location.href = `https://arumo.top/CG9F7Y46?cost=${cost}&external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1454") {
        window.location.href = `https://arumo.top/4Pk76XTD?cost=${cost}&external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1485") {
        window.location.href = `https://arumo.top/gdxVcd?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1486") {
        window.location.href = `https://arumo.top/1kT1tGf8?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1517") {
        window.location.href = `https://arumo.top/wKFxfy89?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1516") {
        window.location.href = `https://arumo.top/ZzsdX7?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}&id=1516`;
      } else {
        window.location.href = `https://arumo.top/CmzG6Dkt?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      }
    } else {
      pwaInstall({
        title: "Install Web App",
      })
        .then(() => {
          setAnimating(true);
          animationMegabyte();
          setTimeout(() => {
            localStorage.setItem("isAlreadyDownload", true);
            setAnimating("done");
          }, 17400);

          async function handleNotifications() {
            try {
              const permissionResult = await Notification.requestPermission();
              if (permissionResult === "granted") {
              } else {
                console.log("Notification permission denied");
              }
            } catch (error) {
              console.error("Error requesting notification permission:", error);
            }
            setAnimating("done");
          }

          setTimeout(() => {
            handleNotifications();
          }, 17700);
        })
        .catch((e) => {
          console.log(e);
          // setTimeout(() => {
          //   window.location.href =
          //     `https://arumo.top/GLJkpJ4M?a=b` +
          //     (sub_id_1 !== null ? `&sub_id_1=${sub_id_1}` : "") +
          //     (sub_id_2 !== null ? `&sub_id_2=${sub_id_2}` : "") +
          //     (sub_id_3 !== null ? `&sub_id_3=${sub_id_3}` : "");
          // }, 400);
        });
    }
    setTimeout(() => {
      document.querySelector(".MuiButton-containedPrimary").click();
    }, 5);
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              {supported() ? (
                <Main
                  handleClick={handleClick}
                  animating={animating}
                  setAnimating={setAnimating}
                  megabyte={megabyte}
                  isSupported={true}
                />
              ) : isInstalled() ? (
                <Installed />
              ) : (
                <>
                  <Main
                    handleClick={handleClick}
                    animating={animating}
                    setAnimating={setAnimating}
                    megabyte={megabyte}
                    isSupported={false}
                  />
                </>
              )}
            </div>
          }
        />
      </Routes>
    </>
  );
}

export default App;
