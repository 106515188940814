export const Installed = () => {
  const param = new URLSearchParams(document.location.search);

  let source = param.get("source");
  let creative_id = param.get("creative_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let offer = param.get("offer");
  let creo_id = param.get("creo_id");
  let external_id = param.get("external_id");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let sub_id_1 = param.get("sub_id_1");
  let sub_id_2 = param.get("sub_id_2");
  let sub_id_3 = param.get("sub_id_3");
  let id = param.get("id");

  if (!cost) {
    cost = localStorage.getItem("cost");
  }

  if (!id) {
    id = localStorage.getItem("id");
  }

  if (!sub_id_1) {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }

  if (!sub_id_2) {
    sub_id_2 = localStorage.getItem("sub_id_2");
  }

  if (!sub_id_3) {
    sub_id_3 = localStorage.getItem("sub_id_3");
  }

  if (!creo_id) {
    creo_id = localStorage.getItem("creo_id");
  }

  if (!external_id) {
    external_id = localStorage.getItem("external_id");
  }

  if (!site_id) {
    site_id = localStorage.getItem("site_id");
  }

  if (!source) {
    source = localStorage.getItem("source");
  }

  if (!creative_id) {
    creative_id = localStorage.getItem("creative_id");
  }

  if (!ad_campaign_id) {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }

  if (!offer) {
    offer = localStorage.getItem("offer");
  }

  setTimeout(() => {
    if (offer === "CmzG6Dkt") {
      window.location.href = `https://arumo.top/CmzG6Dkt?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (offer === "G3qQdFM7") {
      window.location.href = `https://arumo.top/G3qQdFM7?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (offer === "WFLZf7DD") {
      window.location.href = `https://arumo.top/WFLZf7DD?ad_campaign_id=${ad_campaign_id}`;
    } else if (offer === "g3LKs2PF") {
      window.location.href = `https://arumo.top/g3LKs2PF?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (offer === "9NPZBMJX") {
      window.location.href = `https://arumo.top/9NPZBMJX?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1367") {
      window.location.href = `https://arumo.top/s4zmGtMW?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1370") {
      window.location.href = `https://arumo.top/yYQ4dHSw?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1365") {
      window.location.href = `https://arumo.top/b2yrLWXY?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}&sub_id_1=${sub_id_1}`;
    } else if (id === "1371") {
      window.location.href = `https://arumo.top/DVXdfgbT?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}&sub_id_1=${sub_id_1}&sub_id_2=${sub_id_2}`;
    } else if (id === "1452") {
      window.location.href = `https://arumo.top/CG9F7Y46?cost=${cost}&external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1454") {
      window.location.href = `https://arumo.top/4Pk76XTD?cost=${cost}&external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1485") {
      window.location.href = `https://arumo.top/gdxVcd?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1486") {
      window.location.href = `https://arumo.top/1kT1tGf8?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1517") {
      window.location.href = `https://arumo.top/wKFxfy89?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1516") {
      window.location.href = `https://arumo.top/ZzsdX7?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}&id=1516`;
    } else {
      window.location.href = `https://arumo.top/CmzG6Dkt?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    }
  }, 400);

  setTimeout(() => {
    document.getElementById("openApp").click();
  }, 17400);

  return <div></div>;
};
